import React from "react"
import styled from 'styled-components'
import StyledLinkButton from '../components/common/StyledLinkButton'
import { myContext } from '../context/LanguageContext'

const StyledFullScreenDiv = styled.div`
    background-color: #deffdf;
    color: #2f5830;
    height: 100vh;
`;

const StyledDiv = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;

    h1{
        margin-bottom: 2rem;
    }
`;

const NotFoundPage = (props) => {
  // 
  // Find de route when en fails
  let deLocation = "";
  // Check if the first there positions of pathname ar "/en"
  if(props.location.pathname.slice(0,3) === "/en"){
    // 
    deLocation = props.location.pathname.substring(3);
    // 
    return(
      <StyledFullScreenDiv>
      <StyledDiv>
      <h1>404: Not Found</h1>
      <h3>Sorry, the page you'r looking for has no translated version.</h3>
          <myContext.Consumer>
            {context => (
              <StyledLinkButton herobutton="true" to={deLocation}>Show german page</StyledLinkButton>
              // Change lang to german
              // <StyledLinkButton herobutton="true" to={deLocation} onClick={() => context.changeLang()}>Back to german page</StyledLinkButton>
            )}
          </myContext.Consumer>
      </StyledDiv>
    </StyledFullScreenDiv>
  )
  } else {
    return (
      <StyledFullScreenDiv>
        <StyledDiv>
          <h1>404: Nicht gefunden</h1>
          <h3>Leider hast Du eine eine Seite getroffen, die nicht existiert.</h3>
          <StyledLinkButton herobutton="true" to="/">Zurück zur Startseite</StyledLinkButton>
        </StyledDiv>
      </StyledFullScreenDiv>
    )
  }

}

export default NotFoundPage
